import React from 'react';
import {useState,useEffect,Component} from 'react';
import { useNavigate } from 'react-router-dom';
import { BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom';
import moment from 'moment/moment';


import './Resources/dashboard.css';
import axios from './../axios.js';
import Navbar from './Supportingfiles/navbar';
import Sidebar from './Supportingfiles/sidebar';
//import Create_customers from './create_customers';



import Chart from "chart.js/auto";
import { Line, Bar } from "react-chartjs-2";

function Employee_customers(){


    
 //Checking Sessions
 // Access value associated with the login status
 const logstatus=sessionStorage.getItem("logStatus00");
 const logtype=sessionStorage.getItem("logType00");
 const logid=sessionStorage.getItem("logId00");
 const token=sessionStorage.getItem("logToken00");
 const branch_id=sessionStorage.getItem("branchId00");

 /* PAGE VARIABLES */


 
{/* TRANSACTIONS TABLE COLUMNS MANAGEMENT VARIABLES START */}
const[cd_col1_val,setcd_col1_val] = useState(0);//column1
const[cd_col2_val,setcd_col2_val] = useState(0);//column2
const[cd_col3_val,setcd_col3_val] = useState(0);//column3
const[cd_col4_val,setcd_col4_val] = useState(0);//column4
const[cd_col5_val,setcd_col5_val] = useState(0);//column5
const[cd_col6_val,setcd_col6_val] = useState(0);//column6
const[cd_col7_val,setcd_col7_val] = useState(0);//column7
const[cd_col8_val,setcd_col8_val] = useState(0);//column8
const[cd_col9_val,setcd_col9_val] = useState(0);//column9
const[cd_col10_val,setcd_col10_val] = useState(0);//column10
const[cd_col11_val,setcd_col11_val] = useState(0);//column11
{/* const[cd_col12_val,setcd_col12_val] = useState(0);//column12
const[cd_col13_val,setcd_col13_val] = useState(0);//column13
const[cd_col14_val,setcd_col14_val] = useState(0);//column14 */}





//Variables for pagination
const [currentPage, setCurrentPage] = useState(1);
const [pageNumber, setpageNumber] = useState(1);



 //Error Variable
 const[errorvar,seterrorvar] = useState();


 //FILTER VARIABLES
//filter_val
const[filterval,setfilterval]=useState(0);
const[filter_customer_id,setfilter_customer_id]=useState();const updatefilter_customer_id = (event) => { setfilter_customer_id(event.target.value);}; const[filter_customer_id_er,setfilter_customer_id_er]=useState();
const[filter_account_number,setfilter_account_number]=useState();const updatefilter_account_number = (event) => { setfilter_account_number(event.target.value);}; const[filter_account_number_er,setfilter_account_number_er]=useState();
const[filter_name,setfilter_name]=useState();const updatefilter_name = (event) => { setfilter_name(event.target.value);}; const[filter_name_er,setfilter_name_er]=useState();
const[filter_email,setfilter_email]=useState();const updatefilter_email = (event) => { setfilter_email(event.target.value);}; const[filter_email_er,setfilter_email_er]=useState();
const[filter_contact,setfilter_contact]=useState();const updatefilter_contact = (event) => { setfilter_contact(event.target.value);}; const[filter_contact_er,setfilter_contact_er]=useState();
const[filter_group_name,setfilter_group_name]=useState();const updatefilter_group_name = (event) => { setfilter_group_name(event.target.value);}; const[filter_group_name_er,setfilter_group_name_er]=useState();



 
//Variables
const[data,setData] = useState([]);//SetData Variable


//ERROR VARIABLES 
const[error_msg,seterror_msg]=useState();


 useEffect(()=>{
   
  getData();
  
  },[])
 





//******************* GET TRANSACTIONS DATA FUNCTION START  ********************//
function getData(){
 

  //Checking if user loggedin
  if(((logstatus!=null)||(logstatus!=""))&&((logid!=null)||(logid!=""))&&((token!=null)||(token!=" "))&&(branch_id>0)){
 
 axios.post("crm-employee-get-customers-list", {
   Log_id:logid,
   Token:token,
   
   //Code Edits for pagination
   'page' : pageNumber,

   'Branch_id' :branch_id,
   
   //Filters
   'Filter_val' :filterval,
   'Filter_customer_id' :filter_customer_id,
   'Filter_name' :filter_name,
   'Filter_email' :filter_email,
   'Filter_contact' :filter_contact,
   'Filter_account_number' :filter_account_number,
   'Filter_group_name' :filter_group_name,

   })
 .then((response) => {

  //Invalid Session
  if((response.data.error)==100){ 
      //Remove Session Variables and redirect to login
     sessionStorage.removeItem("logStatus00");
     sessionStorage.removeItem("logId00");
     sessionStorage.removeItem("logToken00");
     sessionStorage.removeItem("logName00");

     window.location.replace("/");
  }
  //Success Data Fetched
  if(response.data.successvar==1){
   //Setting Data
   setData(response.data.data.data);
  }

 
  if(response.data.validatorerror){

     //FILTER DATA
    //Validation Errors
     setfilter_customer_id_er(response.data.validatorerror.Filter_customer_id);
     setfilter_name_er(response.data.validatorerror.Filter_name);
     setfilter_email_er(response.data.validatorerror.Filter_email);
     setfilter_contact_er(response.data.validatorerror.Filter_contact);
     setfilter_account_number_er(response.data.validatorerror.Filter_account_number);
     setfilter_group_name_er(response.data.validatorerror.Filter_group_name);
     seterrorvar("Validation error!");

  }

  //Data Violation
  if(response.data.error==102){ 
  
      //Setting Filter Violation Errors
      setfilter_customer_id_er(response.data.filter_customer_id_er);
      setfilter_name_er(response.data.filter_name_er);
      setfilter_email_er(response.data.filter_email_er);
      setfilter_contact_er(response.data.filter_contact_er);
      setfilter_account_number_er(response.data.filter_account_number_er);
      setfilter_group_name_er(response.data.filter_group_name_er);
     seterrorvar("Violation Error");


  }


  //Cannot Fetch Data
  else{
   seterrorvar("Cannot Fetch Data !");
  }
 
 
 });
 
 }
 //User not Logedin
 else{
 
 //Remove Session Variables and redirect to login
   sessionStorage.removeItem("logStatus00");
   sessionStorage.removeItem("logId00");
   sessionStorage.removeItem("logToken00");
   sessionStorage.removeItem("logName00");

   window.location.replace("/");
 
  }
 
 }
  
 //******************* GET TRANSACTIONS DATA FUNCTION END  ********************//

return(
    <>
<html lang="en">
  <head>
    {/*} Required meta tags */}
    <meta charset="utf-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
    <title>Pragati Prathmik Sakh Sahkari Samiti Maryadit | Employee Dashboard</title>
    {/*} plugins:css */}
    <link rel="stylesheet" href="panel/assets/vendors/mdi/css/materialdesignicons.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/flag-icon-css/css/flag-icon.min.css" />
    <link rel="stylesheet" href="panel/assets/vendors/css/vendor.bundle.base.css" />
    {/*} endinject */}
    {/*} Plugin css for this page */}
    <link rel="stylesheet" href="panel/assets/vendors/jquery-bar-rating/css-stars.css" />
    <link rel="stylesheet" href="panel/assets/vendors/font-awesome/css/font-awesome.min.css" />
    {/*} End plugin css for this page */}
    {/*} inject:css */}
    {/*} endinject */}
    {/*} Layout styles */}
    <link rel="stylesheet" href="panel/assets/css/demo_1/style.css" />
    {/*} End layout styles */}
    <link rel="shortcut icon" href="panel/assets/images/favicon.png" />
  </head>
  <body>
    <div className="container-scroller">

      <Sidebar />
    <div className="container-fluid page-body-wrapper">


       <Navbar />
        {/*} partial */}
        <div className="main-panel">

        

          <div className="content-wrapper pb-0">

          <div className="row">
              <div className="col-12 grid-margin create-form" id="create-form">
             {/*} <Create_cash /> */}
              </div>
          </div>


            <div className="page-header flex-wrap">
            <div className="header-left"></div>
              <div className="header-right d-flex flex-wrap mt-2 mt-sm-0">
                <div className="d-flex align-items-center">
                  <a href="#">
                    <p className="m-0 pe-3">EMPLOYEE PANEL</p>
                  </a>
                  <a className="ps-3 me-4" href="#">
                    <p className="m-0">CUSTOMERS</p>
                  </a>
                </div>
            {/*}    <button type="button" className="btn btn-primary mt-2 mt-sm-0 btn-icon-text"  onClick={()=>{ 
               const createForm = document.getElementById('create-form');
               const currentDisplay = createForm.style.display;
               createForm.style.display = currentDisplay === 'none' ? 'block' : 'none'; }}>
                  <i className="mdi mdi-plus-circle"></i> Add New Customer </button>  */}
              </div>
            </div>

            {/*} chart row starts here */}
            <div className="row">

          
            </div>

            

              {/* CASH TRANSACTIONS TABLE SECTION START */}
                  
             <div className="col-lg-12 grid-margin stretch-card">
                <div className="card">
                  <div className="card-body">
                    <h4 className="card-title">Customer Details</h4>
                    <p className="card-description">manage your customers from here
                    </p>
                    <code>{error_msg}</code>

{/* FILTER SECTION START */}
<hr/>
<div id="filter-top-card" class="row col-12" style={{textAlign:"left",display:"none"}}>
<div class="d-flex justify-content-between">
<div>
<h4 style={{padding:'1rem'}}>Filters</h4>
</div>
<div>

<button type="button" class="btn btn-social-icon" onClick={()=>{ document.getElementById("filter-top-card").style.display="none"; }}>
<i class="mdi mdi-close-box text-danger" style={{fontSize:"2rem"}}></i>
</button>
</div>
</div>

<div class="row">
<div class="col-md-4">
<div class="form-group row">
<label class="col-sm-4 col-form-label">Customer Id</label>
<div class="col-sm-8">
<input type="text" class="form-control" placeholder="CID23456  " id="filter_customer_id" value={filter_customer_id} onChange={updatefilter_customer_id}  />
<code>{filter_customer_id_er}</code>
</div>
</div>
</div>
<div class="col-md-4">
<div class="form-group row">
<label class="col-sm-4 col-form-label">Account Number</label>
<div class="col-sm-8">
<input type="text" class="form-control" placeholder="1111111111188877" id="filter_account_number" value={filter_account_number} onChange={updatefilter_account_number}  />
<code>{filter_account_number_er}</code>
</div>
</div>
</div>
<div class="col-md-4">
<div class="form-group row">
<label class="col-sm-4 col-form-label">Name</label>
<div class="col-sm-8">
<input type="text" class="form-control" placeholder="Arjun Kumar"   id="filter_name" value={filter_name} onChange={updatefilter_name}  />
<code>{filter_name_er}</code>
</div>
</div>
</div>
</div>
<div class="row">
<div class="col-md-4">
<div class="form-group row">
<label class="col-sm-4 col-form-label">Contact</label>
<div class="col-sm-8">
<input type="text" class="form-control" placeholder="91488848848484"   id="filter_contact" value={filter_contact} onChange={updatefilter_contact}  />
<code>{filter_contact_er}</code>
</div>
</div>
</div>
<div class="col-md-4">
<div class="form-group row">
<label class="col-sm-4 col-form-label">Email</label>
<div class="col-sm-8">
<input type="text" class="form-control" placeholder="arjunjdjd@gggs.vvv"   id="filter_email" value={filter_email} onChange={updatefilter_email}  />
<code>{filter_email_er}</code>
</div>
</div>
</div>
<div class="col-md-4">
<div class="form-group row">
<label class="col-sm-4 col-form-label">Group</label>
<div class="col-sm-8">
<input type="text" class="form-control" placeholder="Group 1"   id="filter_group_name" value={filter_group_name} onChange={updatefilter_group_name}  />
<code>{filter_group_name_er}</code>
</div>
</div>
</div>
<div class="col-md-4">
<button class="btn btn-primary" onClick={()=>{ setfilterval(1); getData(); }}>Apply Filters</button>
<br/>
<br/>
<br/>
</div>




</div>


<hr/>                  
</div> 

{/* FITER SECTION END */}
                      

                    <div class="col-12" style={{marginBottom:"2rem"}}>
                        <div class="template-demo d-flex justify-content-between">
                        <div></div>

                        {/* COLUMNS MANAGEMENT DROPDOWN START */}
                          <div class="dropdown">
                          <button class="btn btn-warning" onClick={()=>{ document.getElementById("filter-top-card").style.display="block"; }}><i class="mdi mdi-filter-outline"></i></button>
                        {/*}  <button class="btn btn-primary dropdown-toggle" style={{marginLeft:"1rem"}} type="button" id="dropdownMenuIconButton1" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i class="mdi mdi-table-large"></i>
                            </button> */}
                            <div class="dropdown-menu" aria-labelledby="dropdownMenuIconButton1">
                              <h6 class="dropdown-header">Columns</h6>
                              <hr/>
                              
                              {/* Column 1 */}
                              <li><div class="d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Sn</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c1_yes" onClick={()=>{setcd_col1_val(1); document.getElementById("cd_c1_yes").style.display="none"; document.getElementById("cd_c1_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c1_no" style={{ display:"none"}} onClick={()=>{ setcd_col1_val(0); document.getElementById("cd_c1_yes").style.display="block"; document.getElementById("cd_c1_no").style.display="none"; }}></i>
                              </div></div></li> 
                               {/* Column 2 */}
                               <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Tr No.</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c2_yes"  onClick={()=>{setcd_col2_val(1); document.getElementById("cd_c2_yes").style.display="none"; document.getElementById("cd_c2_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c2_no" style={{ display:"none"}} onClick={()=>{ setcd_col2_val(0); document.getElementById("cd_c2_yes").style.display="block"; document.getElementById("cd_c2_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 3 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Date</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c3_yes"  onClick={()=>{setcd_col3_val(1); document.getElementById("cd_c3_yes").style.display="none"; document.getElementById("cd_c3_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c3_no" style={{ display:"none"}} onClick={()=>{ setcd_col3_val(0); document.getElementById("cd_c3_yes").style.display="block"; document.getElementById("cd_c3_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 4 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Transaction</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c4_yes"  onClick={()=>{setcd_col4_val(1); document.getElementById("cd_c4_yes").style.display="none"; document.getElementById("cd_c4_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c4_no" style={{ display:"none"}} onClick={()=>{ setcd_col4_val(0); document.getElementById("cd_c4_yes").style.display="block"; document.getElementById("cd_c4_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 5 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Particular</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c5_yes"  onClick={()=>{setcd_col5_val(1); document.getElementById("cd_c5_yes").style.display="none"; document.getElementById("cd_c5_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c5_no" style={{ display:"none"}} onClick={()=>{ setcd_col5_val(0); document.getElementById("cd_c5_yes").style.display="block"; document.getElementById("cd_c5_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 6 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Amount In</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c6_yes"  onClick={()=>{setcd_col6_val(1); document.getElementById("cd_c6_yes").style.display="none"; document.getElementById("cd_c6_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c6_no" style={{ display:"none"}} onClick={()=>{ setcd_col6_val(0); document.getElementById("cd_c6_yes").style.display="block"; document.getElementById("cd_c6_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 7 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Amount Out</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c7_yes" onClick={()=>{setcd_col7_val(1); document.getElementById("cd_c7_yes").style.display="none"; document.getElementById("cd_c7_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c7_no" style={{ display:"none"}} onClick={()=>{ setcd_col7_val(0); document.getElementById("cd_c7_yes").style.display="block"; document.getElementById("cd_c7_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 8 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Updated By</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c8_yes" onClick={()=>{setcd_col8_val(1); document.getElementById("cd_c8_yes").style.display="none"; document.getElementById("cd_c8_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c8_no" style={{ display:"none"}} onClick={()=>{ setcd_col8_val(0); document.getElementById("cd_c8_yes").style.display="block"; document.getElementById("cd_c8_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 9 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10">Updated At</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c9_yes" onClick={()=>{setcd_col9_val(1); document.getElementById("cd_c9_yes").style.display="none"; document.getElementById("cd_c9_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c9_no" style={{ display:"none"}} onClick={()=>{ setcd_col9_val(0); document.getElementById("cd_c9_yes").style.display="block"; document.getElementById("cd_c9_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 10 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10 text-warning">Edit</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c10_yes" onClick={()=>{setcd_col10_val(1); document.getElementById("cd_c10_yes").style.display="none"; document.getElementById("cd_c10_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c10_no" style={{ display:"none"}} onClick={()=>{ setcd_col10_val(0); document.getElementById("cd_c10_yes").style.display="block"; document.getElementById("cd_c10_no").style.display="none"; }}></i>
                              </div></div></li> 
                              {/* Column 11 */}
                              <li><div class=" d-flex" style={{flexDirection:"row"}}><div style={{marginLeft:"0.2rem"}} class="col-sm-10 text-danger">Delete</div>
                              <div class="col-sm-2"><i class="mdi mdi-eye text-primary" id="cd_c11_yes" onClick={()=>{setcd_col11_val(1); document.getElementById("cd_c11_yes").style.display="none"; document.getElementById("cd_c11_no").style.display="block"; }}></i>
                              <i class="mdi mdi-eye-off text-danger" id="cd_c11_no" style={{ display:"none"}} onClick={()=>{ setcd_col11_val(0); document.getElementById("cd_c11_yes").style.display="block"; document.getElementById("cd_c11_no").style.display="none"; }}></i>
                              </div></div></li> 
                              
                           
                            {/*}  <div class="dropdown-divider"></div>
                              <button class="btn btn-danger">Close</button>*/}
                            </div> 
                          </div>
                          {/* COLUMNS MANAGEMENT DROPDOWN END */}

                          </div>
                          </div>


                    <div className="table-responsive">
                      <table className="table table-hover table-bordered">
                        <thead>
                          <tr >
                          <th style={{fontWeight:"bold"}} hidden={cd_col1_val==1} >Sn</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col2_val==1} >Customer Id</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col3_val==1} >Customer Name</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col3_val==1} >Group</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col4_val==1} >Account Number</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col5_val==1} >Email</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col6_val==1} >Contact</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col7_val==1} >Status</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col10_val==1} >Manage</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col8_val==1} >Updated By</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col9_val==1} >Last Updated</th>
                            <th  style={{fontWeight:"bold"}} hidden={cd_col11_val==1}>Gdrive</th>
                          </tr>
                        </thead>
                        <tbody>
                        {
                       data.map((curElm,ind)=>{

                         return(
                         <>
                        <tr>
                          <td>{curElm.Customer_no}</td>
                          <td>{curElm.Customer_id}</td>
                          <td>{curElm.First_name} {curElm.Last_name}</td>
                          <td>{curElm.Group_name}</td>
                          <td>{curElm.Account_number}</td>
                          <td>{curElm.Email}</td>
                          <td>{curElm.Contact}</td>
                          <td><label className="badge badge-success" hidden={curElm.Customer_status=="inactive"}>active</label><label className="badge badge-danger" hidden={curElm.Customer_status=="active"}>inactive</label></td>
                          <td><button class="btn btn-primary" onClick={()=>{
                            //Edit Redirect
                            sessionStorage.setItem("EditId00",curElm.Customer_no);
                            window.location.replace("/employee-dashboard-customer-profile")
                          }}>Manage</button></td>
                          <td>{curElm.Updated_by} <span hidden={curElm.C_account_type=="admin"}>(EMP ID: {curElm.Updated_by_user})</span><span hidden={curElm.C_account_type=="employee"}>(ADMIN ID: {curElm.Updated_by_user})</span></td>
                          <td>{curElm.Date_updated} ({curElm.Time_updated})</td>
                          <td>
                          <form method="POST" action="https://pragatiprathmik.com/gdrivetest/public/initiate-gdrive-upload">
                          <input type="hidden" name="Log_id" value={logid}></input> 
                          <input type="hidden" name="Token" value={token}></input>
                          <input type="hidden" name="Ref_type" value="customer"></input>
                          <input type="hidden" name="Ref_id" value={curElm.Customer_id}></input>
                          <button class="btn btn-light" type="submit"><i class="mdi mdi-folder-upload text-warning"></i></button>
                          </form>
                          </td>
                        
                        </tr>
                        </>
                         )
                         }
					   
	                  		 )
                         }
                      
                       
                          
                         
                        </tbody>
                      </table>
                    </div>
                    
                    {/* PAGINATION SECTION START */}
                    <div>
                       <div class="col-12" style={{marginBottom:"2rem",marginTop:"1rem"}}>
                        <div class="template-demo d-flex justify-content-between">
                        <div></div>

                       
                          <div class="dropdown">
                          <button class="btn" onClick={()=>{ const pgNumber=pageNumber-1; setpageNumber(pgNumber); getData();  }}><i class="mdi mdi-arrow-left-drop-circle text-success"></i></button>
                          <button class="btn" onClick={()=>{ const pgNumber=pageNumber+1; setpageNumber(pgNumber); getData();  }}><i class="mdi mdi-arrow-right-drop-circle text-success"></i></button>
                          </div>
                          </div>
                          </div>
                    </div>
                    {/* PAGINATION SECTION END */}
                  </div>
                </div>
              </div>

            {/* CASH TRANSACTIONS TABLE SECTION END */}

            
          </div>
          {/*} content-wrapper ends */}
          {/*} partial:partials/_footer.html */}
       {/*}   <footer className="footer">
            <div className="d-sm-flex justify-content-center justify-content-sm-between">
              <span className="text-muted text-center text-sm-left d-block d-sm-inline-block">Copyright © 2021 <a href="https://www.bootstrapdash.com/" target="_blank">BootstrapDash</a>. All rights reserved.</span>
              <span className="float-none float-sm-right d-block mt-1 mt-sm-0 text-center">Hand-crafted & made with <i className="mdi mdi-heart text-danger"></i></span>
            </div>
          </footer> */}
          {/*} partial */}
        </div>
        {/*} main-panel ends */}
      </div>
      {/*} page-body-wrapper ends */}
    </div>
    {/*} container-scroller */}
    {/*} plugins:js */}
    <script src="panel/assets/vendors/js/vendor.bundle.base.js"></script>
    {/*} endinject */}
    {/*} Plugin js for this page */}
    <script src="panel/assets/vendors/jquery-bar-rating/jquery.barrating.min.js"></script>
    <script src="panel/assets/vendors/chart.js/Chart.min.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.resize.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.categories.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.fillbetween.js"></script>
    <script src="panel/assets/vendors/flot/jquery.flot.stack.js"></script>
    <script src="panel/assets/js/jquery.cookie.js" type="text/javascript"></script>
    {/*} End plugin js for this page */}
    {/*} inject:js */}
    <script src="panel/assets/js/off-canvas.js"></script>
    <script src="panel/assets/js/hoverable-collapse.js"></script>
    <script src="panel/assets/js/misc.js"></script>
    <script src="panel/assets/js/settings.js"></script>
    <script src="panel/assets/js/todolist.js"></script>
    {/*} endinject */}
    {/*} Custom js for this page */}
    <script src="panel/assets/js/dashboard.js"></script>
    {/*} End custom js for this page */}
  </body>
</html>
</>
);

//}
}
export default Employee_customers;